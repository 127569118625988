<template>
  <v-navigation-drawer color="success darken-1" v-model="drawer" absolute temporary>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Spotify Upgrader</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list>
      <v-list-item link>
        <router-link :to="{name: 'upgrade'}" class="text-decoration-none d-flex w-100">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Upgrader</v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-item>
      <v-list-item link>
        <router-link :to="{name: 'migrate'}" class="text-decoration-none d-flex w-100">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Migration</v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
    }
  },
  watch: {
    $route(to, from) {
      this.drawer = false;
    }
  },
  methods: {
    open() {
      this.drawer = true;
    },
    close() {
      this.drawer = false;
    }
  }
}
</script>

<style lang="scss">
.v-list-item__title {
  color: white;
}
.text-decoration-none {
  text-decoration: none !important;
  color: white;
}
</style>